/* lato-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: url("./Constants/fonts/lato-v23-latin/lato-v23-latin-regular.eot"); /* IE9 Compat Modes */
  src: url("./Constants/fonts/lato-v23-latin/lato-v23-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("./Constants/fonts/lato-v23-latin/lato-v23-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./Constants/fonts/lato-v23-latin/lato-v23-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("./Constants/fonts/lato-v23-latin/lato-v23-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./Constants/fonts/lato-v23-latin/lato-v23-latin-regular.svg#Lato")
      format("svg"); /* Legacy iOS */
}

/* lora-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Lora";
  font-style: normal;
  font-weight: 400;
  src: url("./Constants/fonts/lora-v32-latin/lora-v32-latin-regular.eot"); /* IE9 Compat Modes */
  src: url("./Constants/fonts/lora-v32-latin/lora-v32-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("./Constants/fonts/lora-v32-latin/lora-v32-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./Constants/fonts/lora-v32-latin/lora-v32-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("./Constants/fonts/lora-v32-latin/lora-v32-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./Constants/fonts/lora-v32-latin/lora-v32-latin-regular.svg#Lora")
      format("svg"); /* Legacy iOS */
}

/* montserrat-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("./Constants/fonts/montserrat-v25-latin/montserrat-v25-latin-regular.eot"); /* IE9 Compat Modes */
  src: url("./Constants/fonts/montserrat-v25-latin/montserrat-v25-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("./Constants/fonts/montserrat-v25-latin/montserrat-v25-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./Constants/fonts/montserrat-v25-latin/montserrat-v25-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("./Constants/fonts/montserrat-v25-latin/montserrat-v25-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./Constants/fonts/montserrat-v25-latin/montserrat-v25-latin-regular.svg#Montserrat")
      format("svg"); /* Legacy iOS */
}

/* raleway-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  src: url("./Constants/fonts/raleway-v28-latin/raleway-v28-latin-regular.eot"); /* IE9 Compat Modes */
  src: url("./Constants/fonts/raleway-v28-latin/raleway-v28-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("./Constants/fonts/raleway-v28-latin/raleway-v28-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("./Constants/fonts/raleway-v28-latin/raleway-v28-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("./Constants/fonts/raleway-v28-latin/raleway-v28-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("./Constants/fonts/raleway-v28-latin/raleway-v28-latin-regular.svg#Raleway")
      format("svg"); /* Legacy iOS */
}
